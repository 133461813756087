import Pagination from '@mui/material/Pagination';
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { BiUpArrowAlt } from "react-icons/bi";
import { BsFilterLeft } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import ApproveTipPopup from "../../components/common/modals/ApprovePopup";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import SearchInput from "../../components/ui/SearchInput";
import { ErrorMessages } from "../../constants/Labels";
import { deleteTip, getContributeList, getTipDetailsByID } from "../../services/ContributeService";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import ContributeMetrics from "./ContributeMetrics";
import ViewContributeTip from "./ViewContributeTip";


export default function Contribute() {
    const options = [
        { value: '0', label: 'All' },
        { value: '5', label: 'Popular' },
        { value: '1', label: 'Most Bookmarked' },
        { value: '2', label: 'Awaiting Approval' },
        { value: '3', label: 'ByDate' },
    ];

    const metricsFilters = [
        { label: 'All', value: 'All', id: 0 },
        { label: 'ByDate', value: 'ByDate', id: 1 },
    ]
    const [deleteItem, setDeleteItem] = useState()
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [tipDetials, setshowTipDetails] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedListType, setSelectedListType] = useState('tipsLog')
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const [totalCount, setTotalCount] = useState(0);
    const recordsPerPage = 10; // Number of records per page
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [dates, setDate] = useState<any>()
    const [orderBy, setOrderBy] = useState<any>('Asc');
    const [sortValue, setSortValue] = useState('')
    const [contributeData, setContributeData] = useState([])
    const [orderBytopicNames, setOrderBytopicNames] = useState('Asc')
    const [orderBytitle, setOrderBytitle] = useState('Asc')
    const [OrderByFlagged, setOrderByFlagged] = useState('Asc')
    const [orderByUpvotes, setOrderByUpvotes] = useState('Asc')
    const [orderByDate, setorderByDate] = useState('Asc')
    const [orderByApprovedDate, setOrderByApprovedDate] = useState('Asc')
    const [selectedMetricsSortOption, setSelectedMetricsSortOption] = useState<any>('');
    const [showMetricsCalender, setShowMetricsCalender] = useState(false)
    const [showApprovePopup, setshowApprovePopup] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [tempStartDate, setTempStartDate] = useState<any>(null);
    const [tempEndDate, setTempEndDate] = useState<any>(null);



    const navigate = useNavigate()

    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some((ref) => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };
    // Delete conform popup open 
    const deleteConform = (item: any) => {
        setDeleteItem(item)
        setShowDeletePopup(true)
    }
    // success handle for delete
    const successHandle = () => {
        let obj = {
            "tipId": deleteItem
        }
        deleteTip(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getContributeListData();
                setShowDeletePopup(false)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }

    // To send the utcoff set time
    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };
    // TO get the list data
    const getContributeListData = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            'filterBy': selectedOption,
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "sortColumnName": sortValue,
            "sortColumnDirection": orderBy,
            "utcOffset": (startDate && endDate !== '' ? utcOffset : ''),
            "category": searchTerm,
        }
        getContributeList(obj).then((response: any) => {
            if (response?.status == true) {
                setContributeData(response.tipsLog[0].tipsLog)
                setTotalCount(response.tipsLog[0].TotalCount);
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages?.SomethingWrong)
        })
    }

    // Dropdown selections of dates
    const handleSelect = (date: any) => {
        const startDate = moment(date.selection.startDate).startOf('day').format('YYYY-MM-DD');
        const endDate = moment(date.selection.endDate).startOf('day').format('YYYY-MM-DD');
        setDate(date)
        setTempStartDate(startDate);
        setTempEndDate(endDate);
    };

    const selectionRange = {
        startDate: moment(tempStartDate).toDate(),
        endDate: moment(tempEndDate).toDate(),
        key: 'selection',
    };
    // To add custum filter to calender
    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: 'Custom',
            range: () => ({
                startDate: new Date(),
                endDate: new Date(),
            }),
            isSelected(range: any) {
                return range.custom;
            },
        },
    ]);

    // custum selected dates submit
    const setCustumDates = () => {
        let SDates = moment(dates?.selection?.startDate).startOf('day').format('YYYY-MM-DD');
        let EDates = moment(dates?.selection?.endDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(SDates);
        setEndDate(EDates);
        setShowCalendar(false)

    }
    // cancel the selected dates
    const resetTime = () => {
        setStartDate(" ")
        setEndDate(" ")
        setShowCalendar(false)
        setShowMetricsCalender(false)
    }
    const handleDropdownClick = (event: any) => {
        if (!event.target.closest('.smart-date-picker')) {
            if (showCalendar || showMetricsCalender) {
                setShowCalendar(false);
                setShowMetricsCalender(false)
                setStartDate('');
                setEndDate('');
            }
        }
    };
    // Handle change for dropdown selection
    const handleChange = (event: any) => {
        setPageNumber(1)
        setSearchTerm('')
        const value = event.label;
        if (value == 'ByDate') {
            setTimeout(() => {
                setShowCalendar(true)
                setSelectedOption(value)
                setTempStartDate(new Date())
                setTempEndDate(new Date())
            }, 500)

        } else {
            setSelectedOption(value);
            setShowCalendar(false)
            setStartDate('')
            setEndDate('')
            setSortValue('')
        }
    };
    useEffect(() => {
        getContributeListData();
    }, [pageNumber, orderBy, selectedOption, searchTerm, startDate, endDate]);

    // Pagination
    const handlePageChange = (_event: any, value: any) => {
        setPageNumber(value);
    };
    const totalPages = Math.ceil(totalCount / recordsPerPage);

    // To set tab options
    const listMethod = (listMethod: any) => {
        setSelectedListType(listMethod)
    }

    //set sorting Order of all table values  
    const toggleOrder = (type: 'qiscore' | 'upvotes' | 'date' | 'topicNames' | 'usefulness' | 'title' | 'ApprovedDate' | 'flagged') => {
        if (type === 'upvotes') {
            const newOrder = orderByUpvotes === 'Asc' ? 'Desc' : 'Asc';
            setOrderByUpvotes(newOrder);
            setSortValue('upvotesCount')
            setOrderBy(newOrder)
        }
        else if (type === 'date') {
            const newOrder = orderByDate === 'Asc' ? 'Desc' : 'Asc';
            setorderByDate(newOrder);
            setSortValue('createdDate')
            setOrderBy(newOrder)
        }
        else if (type === 'topicNames') {
            const newOrder = orderBytopicNames === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytopicNames(newOrder);
            setSortValue('topicNames')
            setOrderBy(newOrder)
        }
        else if (type === 'title') {
            const newOrder = orderBytitle === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytitle(newOrder);
            setSortValue('title')
            setOrderBy(newOrder)
        }
        else if (type === 'flagged') {
            const newOrder = OrderByFlagged === 'Asc' ? 'Desc' : 'Asc';
            setOrderByFlagged(newOrder);
            setSortValue('flaggedCount')
            setOrderBy(newOrder)
        }
        else if (type === 'ApprovedDate') {
            const newOrder = orderByApprovedDate === 'Asc' ? 'Desc' : 'Asc';
            setOrderByApprovedDate(newOrder);
            setSortValue('approvedOrRejectedDate')
            setOrderBy(newOrder)
        }
    };
    // To open edit popup and get data by id api
    const viewTip = (itemId: any, Status: any) => {
        clearActivityPopup()
        settipStatus(Status)
        getTipDetailsByID(itemId).then((response: any) => {
            if (response.status) {
                setSelectedItem(response.tipDetails[0])
                setshowTipDetails(true)
            } else {
                showToastError(response?.message)
            }

        }).catch((_error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }

    // To navigate to edit tip
    const navToEditTip = (tipId: any) => {
        navigate('/contribute/EditContributeTip', { state: tipId })
    }
    // To approve the tip
    const [selectedApproveId, setSelectedApprovId] = useState()
    const [tipStatus, settipStatus] = useState()

    const approveTip = (tipId: any, status: any) => {
        clearActivityPopup()
        setshowTipDetails(false);
        setSelectedApprovId(tipId)
        setshowApprovePopup(true)
        settipStatus(status)
    };

    // Metrics filter handle change
    const metricsHandleChange = (event: any) => {
        const value = event.value
        setSelectedMetricsSortOption(value); // Set the entire event object
        if (value === 'ByDate') {
            setShowMetricsCalender(true);
            setTempStartDate(new Date())
            setTempEndDate(new Date())
        } else {
            setShowMetricsCalender(false);
            setStartDate('');
            setEndDate('');
        }
    }
    // custum selected dates for metrics submit
    const setCustumDatesMetrics = () => {
        let SDates = moment(dates?.selection?.startDate).startOf('day').format('YYYY-MM-DD');
        let EDates = moment(dates?.selection?.endDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(SDates);
        setEndDate(EDates);
        // getContributeListData();
        setShowMetricsCalender(false)
    }
    // Searchfunctionality
    const handleSearch = (value: any) => {
        setSearchTerm(value);
    };


    return (
        <>
            <div className="container-fluid  px-3">
                {!tipDetials &&
                    <div>
                        <div className="d-flex justify-content-between align-items-center shadow-sm bg-white rounded border p-3" >
                            <h3 className="fw-bold mb-0">Tips </h3>
                        </div>
                        <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                            <div className="d-flex justify-content-end mt-0 align-items-center">
                                <div className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedListType === 'tipsLog' ? 'bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('tipsLog'); setPageNumber(1); setSelectedOption(''); setShowCalendar(false); setSearchTerm('') }}> Tips Log</div>
                                <div className={`p-3  fs-sm py-2 border text-center w-[160px] cursor-pointer rounded  ${selectedListType === 'metrics' ? ' bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('metrics'); setSelectedMetricsSortOption(''); setStartDate(''); setEndDate(''); setShowMetricsCalender(false) }} > Metrics</div>
                            </div>
                            {selectedListType == 'tipsLog' ?
                                <div className="d-flex justify-content-end mt-0 align-items-center">
                                    <div className="me-2">
                                        <SearchInput label='Search' onChange={handleSearch} value={searchTerm} />
                                    </div>
                                    <div className="position-relative smart-dropdown" onClick={handleDropdownClick}>
                                        <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />
                                        <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />

                                        <div style={{ zIndex: '99999' }} className="position-fixed  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                            {showCalendar && (
                                                <div className="" style={{ display: 'contents' }}>
                                                    <DateRangePicker
                                                        ranges={[selectionRange]}
                                                        onChange={handleSelect}
                                                        moveRangeOnFirstSelection={false}
                                                        staticRanges={customStaticRanges}
                                                        inputRanges={[]} // Remove predefined input ranges
                                                        showMonthArrow={true}
                                                    />
                                                    <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                                        <button className="btn btn-primary-outline py-1 h-[30px] d-inline-block leading-6" onClick={resetTime}>Cancel</button>
                                                        <button className="btn btn-primary text-white py-1  ms-2  h-[30px] d-inline-block  leading-6" onClick={setCustumDates}>Submit</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                : <div className="smart-dropdown position-relative" onClick={handleDropdownClick}>
                                    {/*<Dropdown options={metricsFilters} onChange={metricsHandleChange} value={selectedMetricsSortOption} placeholder="Filter" /> 
                                    <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />*/}
                                    <div style={{ zIndex: '99999' }} className="position-fixed  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                        {showMetricsCalender && (
                                            <div className="" style={{ display: 'contents' }}>
                                                <DateRangePicker
                                                    ranges={[selectionRange]}
                                                    onChange={handleSelect}
                                                    moveRangeOnFirstSelection={false}
                                                    staticRanges={customStaticRanges}
                                                    inputRanges={[]} // Remove predefined input ranges
                                                    showMonthArrow={true}
                                                />
                                                <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                                    <button className="btn btn-primary-outline py-1 h-[30px] d-inline-block leading-6" onClick={resetTime}>Cancel</button>
                                                    <button className="btn btn-primary text-white py-1  ms-2  h-[30px] d-inline-block  leading-6" onClick={setCustumDatesMetrics}>Submit</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }

                        </div>
                        {selectedListType == 'tipsLog' &&
                            <>
                                <div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321 table-datat-mw-140">
                                    <table className="table position-relative my-0">
                                        <thead>
                                            <tr className="fw-bold  sticky top-0 z-[9]">
                                                <th scope="col" className="w-[300px]">
                                                    <div onClick={() => toggleOrder('title')} title={orderBytitle === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                        <div className="d-flex align-items-center cursor-pointer">Title <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /> </div>
                                                    </div>
                                                </th>
                                                <th className="w-[195px]" onClick={() => toggleOrder('topicNames')} title={orderBytopicNames === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Topic <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                                <th className="w-[200px]">Submitted By </th>
                                                <th scope="col">
                                                    <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('upvotes')}>
                                                        <div className="d-flex align-items-center">Upvotes <span title={orderByUpvotes === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span></div>
                                                    </div></th>
                                                <th scope="col">
                                                    <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('flagged')}>
                                                        <div className="d-flex align-items-center">Flagged <span title={OrderByFlagged === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span></div>
                                                    </div></th>
                                                <th>Status </th>
                                                <th className="w-[140px]">Reviewed By </th>
                                                <th scope="col"><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('date')}>
                                                    Created Date <span title={orderByDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                                </div></th>
                                                <th scope="col"><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('ApprovedDate')}>
                                                    Review Date  <span title={orderByDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                                </div></th>
                                                <th>Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contributeData && contributeData?.length > 0 ?
                                                (contributeData.map((item: any, index: any) => (
                                                    <tr key={item?.tipId} className={`${contributeData?.length == 1 ? 'moreoptions-position-fixed' : ''}`}>
                                                        <td className="cursor-pointer  hover:text-primary" onClick={() => viewTip(item?.tipId, item?.status)} title={item?.title}>{item?.title?.length > 15 ? item?.title?.slice(0, 15) + '...' : item?.title}</td>
                                                        <td className="!min-w-[200px]" title={item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ')}>
                                                            {(() => {
                                                                const topics = item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ');
                                                                const maxLength = 20; // Specify the maximum length of the displayed string
                                                                return topics?.length > maxLength ? `${topics?.slice(0, maxLength)}...` : topics;
                                                            })()}
                                                        </td>
                                                        <td>{item?.submittedBy}</td>
                                                        <td className="w-[200px]"> <div className=" d-flex justify-content-start"><BiUpArrowAlt />{item?.upvotesCount}</div> </td>
                                                        <td>{item?.flaggedCount}</td>
                                                        <td>
                                                            {item.status == 1 ? (<button className="btn btn-sm btn-primary text-white min-w-[100px] status-badge">Approved</button>) : item.status == 2 ? (<button className="btn btn-sm btn-danger  text-white min-w-[100px] status-badge">Rejected</button>) : item.status == 3 ? (
                                                                <button className="btn btn-sm btn-secondary  text-white min-w-[100px] status-badge">Pending</button>) : null}
                                                        </td>
                                                        <td className="w-[140px]">{item?.approvedOrRejectedBy ? item?.approvedOrRejectedBy : '-'}</td>
                                                        <td>{formatDates(item?.createdDate)}</td>
                                                        <td>{item?.approvedOrRejectedDate ? formatDates(item?.approvedOrRejectedDate) : '-'}</td>
                                                        <td className="actions-column ">
                                                            <div className="d-flex justify-content-start align-items-center position-relative" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                                                <div className="ml-[20px] cursor-pointer">
                                                                    <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />

                                                                    <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-136px] ml--142 min-w-[145px] shadow-sm mt-[20px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`}>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => viewTip(item?.tipId, item?.status)}>View</div>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => navToEditTip(item?.tipId)}>Edit</div>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => approveTip(item?.tipId, item?.status)}>Approve/Reject</div>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteConform(item?.tipId)}>Delete</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))) : <tr>
                                                    <td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6></td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div><div className="d-flex justify-content-end mt-2 ">
                                    <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                        <Pagination
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={pageNumber}
                                            onChange={handlePageChange}
                                            siblingCount={1} // Adjust as necessary
                                            boundaryCount={1} // Adjust as necessary
                                            showFirstButton
                                            showLastButton />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                {selectedListType == 'metrics' && <ContributeMetrics selectedSortOption={selectedMetricsSortOption} startDate={startDate} endDate={endDate} />}
                {tipDetials && <ViewContributeTip selectedItem={selectedItem} setshowTipDetails={setshowTipDetails} setpageNumber={setPageNumber} setFilterValue={setSelectedOption} getContributeListData={getContributeListData} tipStatus={tipStatus} setSearchTerm={setSearchTerm} />}
                {deletePopup && <DeleteConformation actionType="Delete" title="Delete Conformation" closeModal={setShowDeletePopup} successHandle={successHandle} />}
                {showApprovePopup && <ApproveTipPopup selectedApproveId={selectedApproveId} setshowApprovePopup={setshowApprovePopup} getListData={getContributeListData} tipStatus={tipStatus} />}

            </div>

        </>


    )
}