import { MultiSelect } from "primereact/multiselect";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoArrowBackOutline } from "react-icons/io5";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../components/ui/Input";
import QuillToolbar, { formats, modules } from "../../components/ui/QuillToolbar";
import { ErrorMessages } from "../../constants/Labels";
import { getLoginUserID } from "../../services/AuthService";
import { deleteTipFile, getTipDetailsByID, UpdateTip } from "../../services/ContributeService";
import { getLookUPData } from "../../services/ResourcesService";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import { isNotEmptyString, notAllowSpaces } from "../../utils/common/Validation";



export default function EditContributeTip() {
    let userDetails = getLoginUserID();
    const navigate = useNavigate()
    const urlPattern = /^(https?:\/\/)?((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-zA-Z\d%_.~+&=]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/i; //working uat url resource, community, contribute
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [webLink, setWebLink] = useState("");
    const [isSensitive, setIsSensitive] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [selectedTagItems, setselectedTagItems] = useState<any>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [newFiles, setNewFiles] = useState<File[]>([]); // Newly added files
    const [tipIdsToDelete, setTipIdsToDelete] = useState<any>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [selectedItem, setSelectedItem] = useState<any>()
    const [Topicoptions, setTopicoptions] = useState<any>([]);
    const [Tagptions, setTagptions] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [inputTagValue, setInputTagValue] = useState("");

    const selectedItemsTooltip = useRef(null);
    const location = useLocation()
    const selectedTipId = location.state

    // To get the lookup data  
    const lookupdata = () => {
        getLookUPData().then((response: any) => {
            setTagptions(response[0]?.Tags);
            setTopicoptions(response[0]?.Topics);
        })
            .catch(() => {
                showToastError("Error while getting lookup data");
            });
    };
    // To view Tip details
    const viewTipDetails = async () => {
        try {
            const response: any = await getTipDetailsByID(selectedTipId);
            setSelectedItem(response?.tipDetails[0]);
        } catch (error) {
            showToastError(ErrorMessages.SomethingWrong);
        }
    };
    // TO call lookup data & tipdetials on load
    useEffect(() => {
        if (selectedTipId !== undefined) {
            viewTipDetails();
            lookupdata();
        }
    }, [selectedTipId]);

    // In edit mode to bind values to the form
    useEffect(() => {
        if (selectedItem && Object.keys(selectedItem).length > 0) {
            setTitle(selectedItem.title);
            setDescription(selectedItem.description);
            setWebLink(selectedItem.link);
            setIsSensitive(selectedItem?.isSensitive)

            const selectedTopics = Array.isArray(selectedItem?.topicNames) ? selectedItem?.topicNames : [];
            setSelectedItems(selectedTopics);

            const selectedTags = Array.isArray(selectedItem?.tagNames) ? selectedItem?.tagNames : [];
            setselectedTagItems(selectedTags);

            if (selectedItem.media) {
                const files = selectedItem.media.map((mediaItem: any) => ({
                    name: mediaItem.FileName,
                    Id: mediaItem.TipMediaID
                }));
                setFiles(files);
                setNewFiles(files);
            } else {
                setFiles([]);
                setNewFiles([]);
            }
        }
    }, [selectedItem]);

    // Form handlechange function
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "webLink":
                setWebLink(value);
                break;

            default:
                break;
        }
    };
    const handleDescriptionChange = (
        value: string,
        delta: any,
        source: any,
        editor: any
    ) => {
        setDescription(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            description: "",
        }));
    };
    const handleCheckboxChange = () => {
        setIsSensitive(prevIsSensitive => !prevIsSensitive);
    };
    // convert tip form data into formdata payload
    const submitHandler = (e: any) => {
        e.preventDefault();
        const tagNames = selectedTagItems?.map((item: any) => item.TagName).join(",");
        const topicNames = selectedItems?.map((item: any) => item.TopicName).join(",");
        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(title)) {
            newErrors.title = ErrorMessages.TitleRequired;
        }
        if (selectedItems.length === 0) {
            newErrors.topic = ErrorMessages.TopicsRequired;
        }
        if (isNotEmptyString(webLink) && !urlPattern.test(webLink)) {
            newErrors.webLink = "Please enter a valid URL";
        }
        setErrors(newErrors);
        // If there are no errors, creating payload
        if (Object.keys(newErrors).length === 0) {
            if (tipIdsToDelete.length > 0) {
                tipIdsToDelete.map((tipId: any) => {
                    let obj = {
                        "tipMediaLinkId": tipId
                    };
                    return deleteTipFile(obj).then((res: any) => {
                    })
                        .catch(error => {
                            showToastError("Failed to delete file but you can proceed with updating the Tip.");
                        });
                });
            }
            const formData = new FormData();
            formData.append("Title", title);
            formData.append("Description", description);
            formData.append("Link", webLink);
            formData.append("ReviewedBy", userDetails?.userId);
            formData.append("TagNames", tagNames ? tagNames : []);
            formData.append("TopicNames", topicNames ? topicNames : []);
            formData.append("TipId", selectedItem.tipId);
            formData.append("IsSensitive", isSensitive.toString());
            const uniqueFromSecondArray = files.filter(item => !newFiles.includes(item));
            // uniqueFromSecondArray.forEach((file: any) => {
            //     formData.append("files", file ? file : ' ');
            // });
            if (files.length > 0) {
                uniqueFromSecondArray.forEach((file: any) => {
                    formData.append("files", file);
                });
            } else {
                formData.append("files", '');
            }
            UpdateTipDeatils(formData)
        }
    };
    const UpdateTipDeatils = (formData: any) => {
        UpdateTip(formData).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message);
                navigate('/contribute')
                return;
            }
            showToastError(response.message);
        })
            .catch(() => {
                showToastError("An error occurred while updating Tip");
            });
    };
    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };
    // Handlers for Tag and Topic changes
    const handleTagChanges = (e: any) => {
        const selectedTags = e.value.map((tagName: string) =>
            Tagptions?.find((tag: any) => tag?.TagName === tagName)
        );
        setselectedTagItems(selectedTags);
    };
    // Handle change to set selected topics
    const handleTopicChanges = (e: any) => {
        const selectedTopics = e.value.map((topicName: string) =>
            Topicoptions?.find((topic: any) => topic?.TopicName === topicName)
        );
        setSelectedItems(selectedTopics);
    };

    // To show newly added Topic
    const handleAddNewItem = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputValue)) {
            event.preventDefault(); // Prevent default behavior
            if (inputValue && !(Topicoptions ?? []).some((option: any) => option?.TopicName === inputValue)) {
                const newTopic = { TopicId: " ", TopicName: inputValue };
                setTopicoptions([...(Topicoptions ?? []), newTopic]);
                setSelectedItems([...selectedItems, newTopic]);
                setInputValue("");
            }
        }
    };
    // To show newly added tag
    const handleAddNewTag = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputTagValue)) {
            event.preventDefault(); // Prevent default behavior
            if (
                inputTagValue &&
                !(Tagptions ?? []).some((option: any) => option.TagName === inputTagValue)
            ) {
                const newTag = { TagId: " ", TagName: inputTagValue };
                setTagptions([...(Tagptions ?? []), newTag]);
                setselectedTagItems([...selectedTagItems, newTag]);
                setInputTagValue("");
            }
        }
    };
    // Template to add new topics
    const headerTemplate = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddNewItem}
                    placeholder="Type and press enter to add new topic"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    // Template to add new tags
    const headerTemplateTag = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputTagValue}
                    onChange={(e) => setInputTagValue(e.target.value)}
                    onKeyDown={handleAddNewTag}
                    placeholder="Type and press enter to add new tags"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };


    return (
        <div className="px-3">
            <div className="bg-white rounded shadow-sm p-3 h-calc-100vh-minus-103 overflow-y-auto">
                <div className="d-flex align-items-center mb-3">
                    <IoArrowBackOutline size={20} className="me-2  cursor-pointer" onClick={() => navigate('/contribute')} />
                    <h3 className="fw-bold my-0 pt-1">Update Tip</h3>
                </div>

                <form onSubmit={submitHandler}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="position-relative mb-3 h-49">
                                <InputField
                                    name="title"
                                    label="Title"
                                    value={title}
                                    placeholder="Enter your Tip Title here "
                                    onChange={handleChange}
                                    error={errors.title}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className="form-group position-relative mb-3 ">
                                <label className="form-group ">Add Topic</label>
                                <div className="smart-multiselect">
                                    <MultiSelect
                                        value={selectedItems?.map((item: any) => item?.TopicName)}
                                        options={Topicoptions?.map((option: any) => ({
                                            label: option?.TopicName,
                                            value: option?.TopicName,
                                        }))}
                                        onChange={handleTopicChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplate}
                                        style={{ width: "100%" }}
                                        placeholder="Select Topics"
                                        ref={selectedItemsTooltip}

                                    />

                                    {errors.topic && (
                                        <div className="error text-danger mt-5">{errors?.topic}</div>
                                    )}
                                </div>
                            </div>
                           {/* <div className="form-group position-relative mb-3">
                                <label className="form-group ">Add Tag</label>
                                <div className="smart-multiselect">
                                    <MultiSelect
                                        value={selectedTagItems?.map((item: any) => item?.TagName)}
                                        options={Tagptions?.map((option: any) => ({
                                            label: option?.TagName,
                                            value: option?.TagName,
                                        }))}
                                        onChange={handleTagChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplateTag}
                                        style={{ width: "100%" }}
                                        placeholder="Select Tags"
                                        ref={selectedItemsTooltip}
                                    />
                                    {errors.tags && (
                                        <div className="error text-danger mt-1">{errors.tags}</div>
                                    )}
                                </div>
                            </div> */}
                            <div className="position-relative mb-3">
                                <InputField
                                    name="webLink"
                                    label="Links"
                                    value={webLink}
                                    placeholder="Web link "
                                    onChange={handleChange}
                                    error={errors.webLink}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className="position-relative mb-3">
                            <div className="d-flex justify-content-start align-items-center">
                            
                            
                            <input
                                type="checkbox"
                                checked={isSensitive}
                                onChange={handleCheckboxChange}
                                className=" form-check-input mt-0 cursor-pointer !w-[18px] !h-[18px] form-check !p-[10px]"
                            /> <span className="p-1 d-inline-block h-[24px] ms-1">Sensitive Content </span>
                            </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="position-relative mb-0 ">
                                <div className="form-group position-relative mb-3 textarea-h-273">
                                    <QuillToolbar />
                                    <div className="" >
                                        <ReactQuill
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            placeholder="Enter your Description here"
                                            modules={modules}
                                            formats={formats}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="d-flex justify-content-end mt-5">
                            <button className="btn btn-primary-outline me-2 px-4" type="button" onClick={() => navigate('/contribute')}> Cancel  </button>
                            <button className="btn btn-primary px-4 text-white" type="submit">   Update </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )

}