import moment from "moment";
import { Chart } from 'primereact/chart';
import { useEffect, useState } from "react";
import { createStaticRanges, DateRangePicker, defaultStaticRanges } from "react-date-range";
import Dropdown from 'react-dropdown';
import { BiSolidUpvote, BiUpArrowAlt } from "react-icons/bi";
import { BsFilterLeft } from "react-icons/bs";
import { GrResources } from "react-icons/gr";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { PiUsersThree } from "react-icons/pi";
import { TbMessages } from "react-icons/tb";
import { GetConversationCount, GetDashboardWidgetCounts, GetTrendingResources, GetTrendingTopics, getUserGraphsData } from "../../services/AuthService";
import { getPieChart, timelineChart } from "../../services/ResourcesService";
import { formatDates } from "../../utils/common/Format";
import { showToastError } from "../../utils/common/ToastMessages";

const Dashboard = () => {

    const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
    const [todayDate, setTodayDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState<string>(''); // Default to "This Week"
    const [trendingTopics, setTrendingTopics] = useState([])
    const [trendingResource, setTrendingResource] = useState([])
    const [widgtsCount, setWidgtsCount] = useState<any>()
    const [conversationCount, setConversationCount] = useState()
    const [showCalendar, setShowCalendar] = useState(false);
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [dates, setDate] = useState<any>()
    const [doughnutData, setDoughnutData] = useState<any>()
    const [filterBy, setFilterBy] = useState('M'); // Default to 'Monthwise'
    const [basicData, setBasicData] = useState({});


    const filterOptions = [
        { label: 'Monthwise', value: 'M' },
        { label: 'Topicwise', value: 'T' },
    ];


    // Donought options
    const [lightOptions] = useState({
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true, // This will use point style for the legend
                    pointStyle: 'circle', // Set point style to circle
                    color: '#495057'
                }
            }
        }
    });

    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true, // This will use point style for the legend
                    pointStyle: 'circle', // Set point style to circle
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    // for montly graph users
    // for monthly graph users
    let horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'oval',
                    color: '#000',
                    font: {
                        size: 14,
                        lineHeight: 1.8
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#313131',
                    font: {
                        size: 12,
                        weight: 'bold'
                    },
                    stepSize: 1 // Ensure ticks are displayed at integer values
                },
                title: {
                    display: true,
                    padding: { top: 1 }
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    // To send the utcoff set time
    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };

    // For users Graphs get API integration 
    const getUsersGraphChart = () => {
        getUserGraphsData().then((response: any) => {
            setChartData({
                labels: response.userStatisticsData.map((data: any) => data.MonthName),
                datasets: [
                    {
                        label: 'Registered Users',
                        backgroundColor: '#015852',
                        data: response.userStatisticsData.map((data: any) => data.RegisteredUsers)
                    },
                    {
                        label: 'Unregistered Users',
                        backgroundColor: '#086861a1',
                        data: response.userStatisticsData.map((data: any) => data.UnregisteredUsers)
                    }
                ]
            });
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // For users Graphs get API integration 
    const GetTrendingTopicsList = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "startDate": startDate,
            "endDate": endDate,
            "filterBy": selectedOption,
            "utcOffset": utcOffset
        }
        GetTrendingTopics(obj).then((response: any) => {
            setTrendingTopics(response?.userStatisticsData)
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // To get dashboard widgets count
    const GetDashboardWidgetCountsList = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "startDate": startDate,
            "endDate": endDate,
            "filterBy": selectedOption,
            "utcOffset": utcOffset
        }
        GetDashboardWidgetCounts(obj).then((response: any) => {
            setWidgtsCount(response?.widgetsCount[0])
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // TO get trending resource list
    const GetTrendingResourcesList = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "startDate": startDate,
            "endDate": endDate,
            "filterBy": selectedOption,
            "utcOffset": utcOffset
        }
        GetTrendingResources(obj).then((response: any) => {
            setTrendingResource(response?.userStatisticsData)
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // To user conversation count
    const getUserConversationCount = async () => {
        const obj = {
            "SecureCode": "8y!X2$zH7n@Q"
        };
        GetConversationCount(obj).then((response: any) => {
            if (response) {
                setConversationCount(response?.total_conversations);
            } else {
                showToastError('Error while fetching conversations count');
            }
        })
            .catch((error: any) => {
                // showToastError('Error while fetching conversations count');
            });

    };

    useEffect(() => {
        setTodayDate(new Date());
        getApis()
    }, [selectedOption])
    // Dropdown filters
    const options = [
        { value: '0', label: 'All' },
        { value: '1', label: 'ByDate' },
    ];
    const handleChange = (event: any) => {
        const value = event.label
        setSelectedOption(value); // Set the entire event object
        if (value === 'ByDate') {
            setShowCalendar(true);
            setStartDate(new Date());
            setEndDate(new Date());
        } else {
            setShowCalendar(false);
            setStartDate('');
            setEndDate('');
        }
    }

    const selectionRange = {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        key: 'selection',
    };
    const handleSelect = (date: any) => {
        const startDate = moment(date.selection.startDate).startOf('day').format('YYYY-MM-DD');
        const endDate = moment(date.selection.endDate).startOf('day').format('YYYY-MM-DD');
        setDate(date)
        setStartDate(startDate);
        setEndDate(endDate);
    };
    // To add custum filter to calender
    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: 'Custom',
            range: () => ({
                startDate: new Date(),
                endDate: new Date(),
            }),
            isSelected(range: any) {
                return range.custom;
            },
        },
    ]);
    // TO call all apis when date filter applied
    const getApis = () => {
        getUsersGraphChart()
        GetTrendingTopicsList()
        GetTrendingResourcesList()
        GetDashboardWidgetCountsList()
        getUserConversationCount()
    }
    // custum selected dates submit
    const setCustumDates = () => {
        let SDates = moment(dates?.selection?.startDate).startOf('day').format('YYYY-MM-DD');
        let EDates = moment(dates?.selection?.endDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(SDates);
        setEndDate(EDates);
        getApis()
        setShowCalendar(false)
    }
    // cancel the selected dates
    const resetTime = () => {
        setStartDate(new Date())
        setEndDate(new Date())
        setShowCalendar(false)
        setSelectedOption('All')
    }
    // Function to fetch chart data from the API
    const fetchChartData = async (filter: any) => {
        let obj = {
            "filterBy": filter,
        }
        timelineChart(obj).then((response: any) => {
            formatChartData(response.timelineChartdata); // Format and set the chart data
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // Function to format and set chart data
    const formatChartData = (timelineChartdata: any) => {
        const formattedData = {
            labels: timelineChartdata[0].Lables.split(', ').map((label: any) => label.trim()),
            datasets: timelineChartdata[0].datasets.map((dataset: any) => ({
                label: dataset.label,
                data: dataset.data.split(', ').map(Number),
                fill: dataset.fill,
                borderColor: dataset.borderColor,
                tension: parseFloat(dataset.tension),
                backgroundColor: dataset.borderColor
            }))
        };
        setBasicData(formattedData);
    };

    const fetchPieChartData = async () => {
        getPieChart().then((response: any) => {
            formattedData(response); // Format and set the chart data
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    const formattedData = (pieChartData: any) => {
        const chartData = pieChartData[0];
        const formattedPieData = {
            labels: chartData.Lables.split(', ').map((label: any) => label.trim()),
            datasets: [
                {
                    data: chartData.datasets[0].data.split(', ').map(Number),
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#C9CBCF', '#36B3EB', '#7B9DFF', '#3A99D9'] // Example colors
                }
            ]
        };

        setDoughnutData(formattedPieData);
    };


    // Fetch the data on component mount and whenever filterBy changes
    useEffect(() => {
        fetchChartData(filterBy);
    }, [filterBy]);
    useEffect(() => {
        fetchPieChartData()

    }, [])
    const handleFilterChange = (e: any) => {
        setFilterBy(e.value);
    };

    return (
        <>
            <div className="container-fluid px-3">
                <div className="d-flex justify-content-between align-items-start">
                    <div>
                        <h2 className="fw-bold mb-0  leading-[32px]	mb-0" >Dashboard </h2>
                        <div>
                            <p className="fw-semibold text-sm mb-0 text-primary mt-0 mb-0 leading-[14px]">
                                {formatDates(todayDate)}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between  my-0 resourcelist-tab-panel">
                        <div className="position-relative smart-dropdown mt-0">
                            {/**<Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />  */}
                            <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                            <div style={{ zIndex: '99999' }} className="position-fixed  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                {showCalendar && (
                                    <div className="" style={{ display: 'contents' }}>
                                        <DateRangePicker
                                            ranges={[selectionRange]}
                                            onChange={handleSelect}
                                            moveRangeOnFirstSelection={false}
                                            staticRanges={customStaticRanges}
                                            inputRanges={[]} // Remove predefined input ranges
                                            showMonthArrow={true}
                                        />
                                        <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3 mt-2">
                                            <button className="btn btn-primary-outline py-1 h-[30px]" onClick={resetTime}><span className=" d-inline-block leading-6">Cancel</span></button>
                                            <button className="btn btn-primary text-white py-1  ms-2  h-[30px]" onClick={setCustumDates}><span className="d-inline-block  leading-6" >Submit</span></button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                </div>
                <div className="d-flex  mt-3 row d-flex">
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{widgtsCount?.UsersCount}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><PiUsersThree size={20} /></span>
                            </div>
                            <span className="mt-3 ">Number of Users</span>
                        </div>

                    </div>
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{widgtsCount?.UpVotesCount}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><BiSolidUpvote size={20} /></span>
                            </div>
                            <span className="mt-4 "> Number of Upvotes</span>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{conversationCount || 0}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><TbMessages size={30} /></span>
                            </div>
                            <span className="mt-4 "> Number of Conversations</span>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{widgtsCount?.ResourcesCount}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><GrResources size={20} /></span>
                            </div>
                            <span className="mt-4 "> Number of Resources</span>
                        </div>
                    </div>
                </div>
                <div className=" mt-4">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="bg-white p-3 shadow-md border rounded-md">
                                <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="fw-bold d-flex mb-3">Timeline Chart of Usage</h4>
                                        <div className="d-flex  mb-3 smart-dropdown position-relative ">
                                            <Dropdown
                                                value={filterBy}
                                                options={filterOptions}
                                                onChange={handleFilterChange}
                                                placeholder="Select Filter"
                                            />
                                            <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                                        </div>
                                    </div>
                                    <Chart type="line" key={filterBy} data={basicData} options={basicOptions} style={{ height: '400px', width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  bg-white p-3 shadow-md border rounded-md justify-content-end "   >
                            <h4 className="fw-bold d-flex mb-3">User Engagement</h4>
                            <div className="h-[400px] " style={{ position: 'relative', width: '100%', margin: '0 auto' }}>
                                <Chart type="doughnut" data={doughnutData} options={lightOptions} style={{ height: '100%', width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="bg-white p-3 shadow-md border rounded-md">
                        <h4 className="fw-bold d-flex mb-3">Users</h4>
                        {chartData && chartData.datasets && chartData.datasets.length > 0 ? (
                            <div className="h-[400px] ">
                                <Chart type="bar" data={chartData} options={horizontalOptions} />
                            </div>
                        ) : (
                            <div className="text-center text-gray h-calc-100vh-minus-669 d-flex justify-content-center align-items-center">No data found</div>
                        )}
                    </div>
                </div>
                <div className="my-3">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="bg-white p-3 shadow-md border rounded-md pb-0 mih-calc-411">
                                <div className="d-flex justify-content-between mb-4">
                                    <h4 className="fw-bold mb-0">Trending Topics</h4>
                                    <HiMiniArrowTrendingUp className="fw-bold text-secondary" size={20} />
                                </div>
                                <div className="h-calc-100vh-minus-516 overflow-y-auto overflow-x-hidden">
                                    {trendingTopics?.length > 0 ? trendingTopics && trendingTopics?.map((item: any, index: any) => (
                                        <div key={index} className="d-flex justify-content-between mb-[10px]">
                                            <h6 className="mb-0 text-capitalize"> {item?.TopicName}</h6>
                                            <p className="d-flex justify-content-between mb-0 fw-bold text-base"> <BiUpArrowAlt className="text-primary pt-0" size={20} /> {item?.TotalUpVotes}</p>
                                        </div>
                                    )) : <div className="text-center text-gray h-100 d-flex align-items-center justify-content-center">No data found</div>}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="bg-white p-3 shadow-md border rounded-md pb-0 mih-calc-411">
                                <div className="d-flex justify-content-between mb-4">
                                    <h4 className="fw-bold mb-0 ">Trending Resources</h4>
                                    <HiMiniArrowTrendingUp className="fw-bold text-secondary" size={20} />
                                </div>
                                <div className="h-calc-100vh-minus-516 overflow-y-auto  overflow-x-hidden">
                                    {trendingResource?.length > 0 ? trendingResource && trendingResource?.map((item: any, index: any) => (
                                        <div key={index} className="d-flex justify-content-between mb-[10px]">
                                            <h6 className="mb-0 text-capitalize" title={item?.Title}>{item?.Title?.length > 80 ? item?.Title?.slice(0, 80) + '...' : item?.Title} </h6>
                                            <p className="d-flex justify-content-between mb-0 fw-bold text-base"> <BiUpArrowAlt className="text-primary pt-0" size={20} /> {item?.TotalUpVotes}</p>
                                        </div>
                                    )) : <div className="text-center text-gray h-100 d-flex align-items-center justify-content-center">No data found</div>}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Dashboard;
